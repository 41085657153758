"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useImageSize = void 0;
const react_1 = require("react");
const getImageSize_1 = require("./getImageSize");
const useImageSize = (url, options) => {
    const [dimensions, setDimensions] = (0, react_1.useState)(null);
    const [loading, setLoading] = (0, react_1.useState)(false);
    const [error, setError] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        const fetch = () => __awaiter(void 0, void 0, void 0, function* () {
            setLoading(true);
            setDimensions(null);
            try {
                const { width, height } = yield (0, getImageSize_1.getImageSize)(url, options);
                setDimensions({ width, height });
            }
            catch (error) {
                setError(error.toString());
            }
            finally {
                setLoading(false);
            }
        });
        fetch();
    }, [url, options]);
    return [dimensions, { loading, error }];
};
exports.useImageSize = useImageSize;
