const baseStyle = {
  color: 'text.900',
  _dark: {
    color: 'text.50',
  },
  fontWeight: '400',
  fontFamily: 'body',
  fontStyle: 'normal',
  fontSize: 'sm',
  letterSpacing: 'md',
  lineHeight: 'lg',
};

const defaultProps = {};

export default { baseStyle, defaultProps };
